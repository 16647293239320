import { h } from 'preact';
import {DetectedCard, Game, Player, updateGame} from "../service/PlayerService";


export interface PlayerComponentState {
    player: Player
}

export function PlayerComponent({ player }: PlayerComponentState) {

    const description = [ player.value.back.name, player.value.middle.name, player.value.front.name ].join(', ')

    const receivedSummary = player.value?.receivedPoints.map(points => <span>P{points.fromIndex + 1}: {points.points}<br/></span>);

    return (
        <tr key={player.index} class="playerrow">
            <td class="player" width="60%">
                <span class="name">Player {player.index + 1}:</span><br/>
                {description}
            </td>
            <td class="royalties" width="10%">
                {player.value?.royalties}
            </td>
            <td class="totalpoints" width="30%">
                {player.value?.totalPoints > 0 ?
                    <span>
                        <span className="points">+{player.value?.totalPoints}</span>
                        <br/>{receivedSummary}
                    </span> : null
                }
            </td>
        </tr>
    );
}

