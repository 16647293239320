import { h } from 'preact';
import {Team} from "../service/SchnapsenGame";

export interface TeamComponentState {
    team: Team
}

export function TeamComponent({ team: team }: TeamComponentState) {
    return (
        <tr key={team.index} class="playerrow">
            <td class="player" width="65%">
                <span class="name">Team {team.index + 1}:</span><br/>
                { team.cards.map(card => card.card).join(', ') }
            </td>
            <td class="royalties" width="15%">
            </td>
            <td width="20%">
                <span className="points">{team.value}</span>
            </td>
        </tr>
    );
}

