import { h } from 'preact';
import {useEffect, useState} from "preact/hooks";
import MainPage from "./poker/pages/MainPage";
import SchnapsenMainPage from "./schnapsen/pages/SchnapsenMainPage";

function App() {
    if (!window.location.search.includes("schnapsen")) {
        return (
            <MainPage />
        );
    } else {
        return (
            <SchnapsenMainPage />
        );
    }
}

export default App;
