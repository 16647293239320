import { h } from 'preact';
import {useCallback, useState} from "preact/hooks";
import VideoStream, {Detection} from "../../components/VideoStream";
import {Game, Player, updateGame} from "../service/PlayerService";
import {PlayerComponent} from "./PlayerComponent";
import {RowValue} from "../service/Hand";
import {getSortedDetectedCards} from "../service/DetectionService";

interface MainPageState {
    streaming: boolean
}

let lastVibrate = Date.now();

const audio = document.getElementById('confirm') as HTMLAudioElement;

const samplePlayer = {
    index: 0,
    cards: [
        { card: '2', confidence: 0.0 },
    ],
    value: {
        hand: {
            index: 0,
            front: [],
            middle: [],
            back: [],
        },
        back: { name: 'XYZ' } as RowValue,
        middle: { name: 'XYZ' } as RowValue,
        front: { name: 'XYZ' } as RowValue,
        royalties: 0,
        totalPoints: 6,
        receivedPoints: [ {
            fromIndex: 1,
            points: 6,
            royalties: 0,
            rowPoints: 0
        }]
    }
} as Player

function MainPage() {

    const [ state, setState ] = useState<MainPageState>({
        streaming: true
    });

    const [ game, setGame ] = useState<Game>({
        players: [  ]
        //players: [ samplePlayer, samplePlayer, samplePlayer, samplePlayer ]
    });

    const stopStreaming = useCallback(() => {
        setState({...state, streaming: false });
    }, [ state ]);

    const start = useCallback(() => {
        setState({...state, streaming: true });
    }, [ state ]);

    const startNew = useCallback(() => {
        setState({...state, streaming: true });
        setGame({ players: [  ] });
    }, [ state ]);

    const onDetect = useCallback((detections: Detection[]) => {

        if (detections.length == 13) {

            let detectedCards = getSortedDetectedCards(detections);
            if (detectedCards.length == detections.length) {

                const newGame = updateGame(game, detectedCards)
                setGame(newGame);

                if (newGame !== game && Date.now() - lastVibrate > 1000) {
                    lastVibrate = Date.now();
                    audio.play();
                    navigator.vibrate(200);
                }

                if (newGame.players.length == 4) {
                    stopStreaming();
                }
            }
        }

    }, [game]);

    return (
        <div>
            { state.streaming
                ? <VideoStream onDetect={onDetect} isValidDetectionCount={count => count == 13} preset="poker" />
                : <div>
                    <p><button onClick={start}>Start</button></p>
                    <p><button onClick={startNew}>Start New</button></p>
                  </div> }

            <table>
                {game.players.map(player => <PlayerComponent key={player.index} player={player} />)}
            </table>

            { state.streaming ? <button onClick={stopStreaming}>Stop</button> : null }

            <br/>
            Version 1.0
        </div>

    );
}

export default MainPage;
