import {DetectedCard, Player} from "./PlayerService";
import {Detection} from "../../components/VideoStream";

/** takes yolo detections sorted by y position.
 *  returns detected cards, sorted from front (3) to middle (5) to back (5) */
export function getSortedDetectedCards(detections: Detection[]): DetectedCard[] {

    // create reversed copy of detections array
    const reversedDetections = detections.slice().reverse().map(detection => {
        const box = detection.box.map(value => 1000 - value);
        return { ...detection, box: box }
    });

    let sortedDetections;
    if (isValidPokerHand(detections)) {
        sortedDetections = detections;
    } else if (isValidPokerHand(reversedDetections)) {
        sortedDetections = reversedDetections;
    } else {
        return [];
    }

    return sortedDetections.map(detection => {
        return {
            card: detection.class_name,
            confidence: detection.confidence,
            y: detection.box[1]
        } as DetectedCard
    });
}

function isValidPokerHand(detections: Detection[]): boolean {
    const frontY = detections[1].box[1]
    const middleY = detections[5].box[1]
    const middleThreshold = (frontY + middleY) / 2
    const backY = detections[10].box[1]
    const backThreshold = (backY + middleY) / 2
    const frontCount = detections.filter(detection => detection.box[1] < middleThreshold).length
    const backCount = detections.filter(detection => detection.box[1] > backThreshold).length
    return frontCount == 3 && backCount == 5;
}