// detect player cards

import {Card} from "./Card";
import {getPlayerHandValues, PlayerHand, PlayerHandValue} from "./Hand";

export interface DetectedCard {
    card: Card,
    confidence: number,
    y: number
}

export interface Player {
    index: number,
    cards: DetectedCard[],
    value?: PlayerHandValue
}

export interface Game {
    players: Player[]
}

function hasPlayerCards(player: Player, cards: DetectedCard[]): boolean {
    return player.cards.some(playerCard => cards.some(card => card.card === playerCard.card));
}

export function updateGame(game: Game, cards: DetectedCard[]): Game {

    // check if any of the given cards is already in the game
    let playerWithCards = game.players.find(player => hasPlayerCards(player, cards));

    let newPlayers = [...game.players];
    if (playerWithCards) {
        // do not change anything
        //newPlayers[newPlayers.indexOf(playerWithCards)] = {...playerWithCards, cards: cards};
        return game;
    } else {
        newPlayers.push({cards: cards, index: newPlayers.length});

        const playerHands = newPlayers.map(player => {
            const cards = player.cards.map(card => card.card)

            if (cards.length < 13) {
                return {
                    index: player.index,
                    front: cards,
                    middle: cards,
                    back: cards
                } as PlayerHand
            } else {
                return {
                    index: player.index,
                    front: cards.slice(0, 3),
                    middle: cards.slice(3, 8),
                    back: cards.slice(8, 13)
                } as PlayerHand
            }


        });

        const playerHandValues = getPlayerHandValues(playerHands);
        playerHandValues.forEach(playerHandValue => {
            newPlayers[playerHandValue.hand.index] = { ...newPlayers[playerHandValue.hand.index], value: playerHandValue };
        });

        return { ...game, players: newPlayers };
    }
}