import {h} from 'preact';
import {useCallback, useEffect} from "preact/hooks";

const VIDEO_WIDTH = 640;
const VIDEO_HEIGHT = 480;

const MIN_CONFIDENCE = 0.80;

export interface Detection {
    class_name: string,
    confidence: number,
    box: number[] // [x, y, width, height]
}

export interface VideoStreamProps {
    onDetect: (detections: Detection[]) => void
    isValidDetectionCount: (detectionCount: number) => boolean
    preset: string
}

async function setupWebcam(videoElement: HTMLVideoElement): Promise<MediaStream> {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        const stream = await navigator.mediaDevices.getUserMedia({ video: {facingMode: "environment"} });
        videoElement.srcObject = stream
        //return new Promise(resolve => videoElement.onloadedmetadata = resolve);
        return stream;
    } else {
        throw new Error('Webcam not supported');
    }
}

let localOnDetect: (detections: Detection[]) => void = () => {};

function VideoStream({ onDetect, isValidDetectionCount, preset }: VideoStreamProps) {

    localOnDetect = onDetect;

    useEffect(() => {

        let videoCanvas = document.createElement('canvas') as HTMLCanvasElement;
        videoCanvas.width = VIDEO_WIDTH;
        videoCanvas.height = VIDEO_HEIGHT;

        let video = document.createElement('video') as HTMLVideoElement;
        video.width = VIDEO_WIDTH;
        video.height = VIDEO_HEIGHT;
        video.autoplay = true;

        let streamPromise = setupWebcam(video);

        let displayCanvas = document.getElementById('display_canvas') as HTMLCanvasElement;
        let keepDrawing = true;

        function handlePredictions(data: any) {

            // visualize predictions
            let context = displayCanvas.getContext('2d')
            context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

            if (video.videoWidth > 0 && displayCanvas.width !== video.videoWidth) {
                displayCanvas.width = video.videoWidth;
                displayCanvas.height = video.videoHeight;
            }

            let detections: Detection[] = [];

            if (isValidDetectionCount(data.predictions.length)) {
                context.beginPath();
                context.lineWidth = 4;
                context.strokeStyle = "green";
                context.rect(2, 2, video.videoWidth - 4, video.videoHeight - 4);
                context.stroke();
            }

            const classNames = {}
            data.predictions.forEach((prediction: any) => {

                if (prediction.confidence >= MIN_CONFIDENCE && !classNames[prediction.class_name]) {
                    classNames[prediction.class_name] = true;
                    detections.push({
                        class_name: prediction.class_name,
                        confidence: prediction.confidence,
                        box: prediction.box
                    });
                }

                // draw rectangle onto canvas
                let rectangle = prediction.box;
                context.beginPath();
                context.lineWidth = 2;
                context.strokeStyle = prediction.confidence >= MIN_CONFIDENCE ? 'green' : 'red';
                context.rect(rectangle[0], rectangle[1], rectangle[2] - rectangle[0], rectangle[3] - rectangle[1]);
                context.stroke();
                context.strokeText(prediction.class_name, rectangle[0], rectangle[1])
            });

            // forward to parent component
            detections.sort((a, b) => a.box[1] - b.box[1]);
            localOnDetect(detections);
        }

        const draw = async () => {

            videoCanvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

            // convert the image data to jpeg
            let pngData = videoCanvas.toDataURL('image/jpeg')
            let base64 = pngData.replace('data:image/jpeg;base64,', '')

            // send the image to the API as a POST request
            const response = await fetch(`/detect?preset=${preset}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({image: base64})
            });

            // parse json response
            try {
                const data = await response.json();
                if (response.status == 200) {
                    handlePredictions(data);
                }
            } catch (e) {
                console.log(e);
            }

            if (keepDrawing) {
                draw();
            }
        };

        draw();
        return () => {
            keepDrawing = false;
            streamPromise.then(stream => stream.getTracks().forEach(track => track.stop()));
        }
    }, []);

    return (
        <div>
            <canvas id="display_canvas" width={VIDEO_WIDTH} height={VIDEO_HEIGHT}></canvas>
        </div>
    );
}

export default VideoStream;
