import {Card} from "./Card";
import {Hand as Solver} from "pokersolver";

export interface ReceivedPoints {
    fromIndex: number,
    royalties: number,
    rowPoints: number,
    points: number
}

export interface PlayerHandValue {
    hand: PlayerHand,
    front: RowValue, // 3 cards
    middle: RowValue, // 5 cards
    back: RowValue, // 5 cards
    royalties: number,
    totalPoints: number,
    receivedPoints: ReceivedPoints[]
}

// Open face chinese poker hand
export interface PlayerHand {
    index: number, // player index
    front: Card[], // 3 cards
    middle: Card[], // 5 cards
    back: Card[] // 5 cards
}

interface CompareFunc {
    (other: RowValue): number;
}

export interface RowValue {
    descr: string, // 5 High
    name: string, // StraightFlush, FourOfAKind, FullHouse, Flush, Straight, ThreeOfAKind, TwoPair, OnePair, HighCard
    isPossible: boolean,
    rank: number,
    compare: CompareFunc
}

type RowRanks = {
    [key: string]: number
}

const ROYALTIES_BACK: RowRanks = {
    'Straight': 2,
    'Flush': 4,
    'Full House': 6,
    'Four of a Kind': 10,
    'Straight Flush': 15,
    'Royal Flush': 25
}

const ROYALTIES_MIDDLE: RowRanks = {
    'Three of a Kind': 2,
    'Straight': 4,
    'Flush': 8,
    'Full House': 12,
    'Four of a Kind': 20,
    'Straight Flush': 30,
    'Royal Flush': 50
}

const ROYALTIES_FRONT: RowRanks = {
    "Pair, 6's": 1,
    "Pair, 7's": 2,
    "Pair, 8's": 3,
    "Pair, 9's": 4,
    "Pair, 10's": 5,
    "Pair, J's": 6,
    "Pair, Q's": 7,
    "Pair, K's": 8,
    "Pair, A's": 9,
    "Three of a Kind, 2's": 10,
    "Three of a Kind, 3's": 11,
    "Three of a Kind, 4's": 12,
    "Three of a Kind, 5's": 13,
    "Three of a Kind, 6's": 14,
    "Three of a Kind, 7's": 15,
    "Three of a Kind, 8's": 16,
    "Three of a Kind, 9's": 17,
    "Three of a Kind, 10's": 18,
    "Three of a Kind, J's": 19,
    "Three of a Kind, Q's": 20,
    "Three of a Kind, K's": 21,
    "Three of a Kind, A's": 22
}

export function getBackRowRoyalties(solvedHand: RowValue): number {
    if (solvedHand.descr == 'Royal Flush') {
        return ROYALTIES_BACK[solvedHand.descr];
    } else {
        const rank = ROYALTIES_BACK[solvedHand.name];
        return rank ?? 0;
    }
}

export function getMiddleRowRoyalties(solvedHand: RowValue): number {
    if (solvedHand.descr == 'Royal Flush') {
        return ROYALTIES_MIDDLE[solvedHand.descr];
    } else {
        const rank = ROYALTIES_MIDDLE[solvedHand.name];
        return rank ?? 0;
    }
}

export function getFrontRowRoyalties(solvedHand: RowValue): number {
    const rank = ROYALTIES_FRONT[solvedHand.descr];
    return rank ?? 0;
}

export function getRowValue(hand: Card[]): RowValue {
    return Solver.solve(hand) as RowValue;
}

export function getPlayerHandValues(hands: PlayerHand[]): PlayerHandValue[] {
    const playerHandValues = hands.map(getPlayerHandValue);
    playerHandValues.forEach(p1 => {
        playerHandValues.forEach(p2 => {
            if (p1.hand.index < p2.hand.index) {

                // if positive: p1 receives points from p2
                // if negative: p2 receives points from p1
                let receivedRoyalties = p1.royalties - p2.royalties;
                let receivedRowPoints;

                if (p1.royalties < 0 && p2.royalties < 0) {
                    // both players fouled, no points differential
                    receivedRoyalties = 0;
                    receivedRowPoints = 0;
                } else if (p1.royalties < 0 || p2.royalties < 0) {
                    // one player fouled, other receives the negative royalties
                    receivedRowPoints = 0;
                } else {
                    // both players did not foul, compare rows
                    const frontPoints = p2.front.compare(p1.front);
                    const middlePoints = p2.middle.compare(p1.middle);
                    const backPoints = p2.back.compare(p1.back);
                    const rowPoints = frontPoints + middlePoints + backPoints;

                    if (Math.abs(rowPoints) >= 3) {
                        // one player scooped the other one
                        receivedRowPoints = rowPoints * 2;
                    } else if (Math.abs(rowPoints) >= 1) {
                        // one player won
                        receivedRowPoints = Math.sign(rowPoints);
                    } else {
                        // tie
                        receivedRowPoints = 0;
                    }
                }

                let receivedPoints = receivedRoyalties + receivedRowPoints;
                if (Math.abs(receivedPoints) > 0) {
                    let receiver = receivedPoints > 0 ? p1 : p2;
                    let sender = receivedPoints > 0 ? p2 : p1;
                    receiver.receivedPoints.push({
                        fromIndex: sender.hand.index,
                        points: receivedPoints * Math.sign(receivedPoints),
                        royalties: receivedRoyalties * Math.sign(receivedPoints),
                        rowPoints: receivedRowPoints * Math.sign(receivedPoints)
                    });
                    receiver.totalPoints += receivedPoints * Math.sign(receivedPoints);
                }

            }
        });
    });
    return playerHandValues;
}

export function getPlayerHandValue(hand: PlayerHand): PlayerHandValue {
    const back = getRowValue(hand.back);
    const middle = getRowValue(hand.middle);
    const front = getRowValue(hand.front);

    const isMiddleOk = middle.compare(back) >= 0;
    const isFrontOk = front.compare(middle) >= 0;

    let royalties;
    if (isMiddleOk && isFrontOk) {
        const royaltiesBack = getBackRowRoyalties(back);
        const royaltiesMiddle = getMiddleRowRoyalties(middle);
        const royaltiesFront = getFrontRowRoyalties(front);
        royalties = royaltiesBack + royaltiesMiddle + royaltiesFront
    } else {
        royalties = -6;
    }

    return {
        back: back,
        front: front,
        middle: middle,
        hand: hand,
        royalties: royalties,
        receivedPoints: [],
        totalPoints: 0
    }
}