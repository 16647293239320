import { h } from 'preact';
import {useCallback, useEffect, useState} from "preact/hooks";
import VideoStream, {Detection} from "../../components/VideoStream";
import {SchnapsenGame, updateGame} from "../service/SchnapsenGame";
import {DetectedCard} from "../../poker/service/PlayerService";
import {TeamComponent} from "./TeamComponent";

interface MainPageState {
    streaming: boolean
}

let lastVibrate = Date.now();

const audio = document.getElementById('confirm') as HTMLAudioElement;


function SchnapsenMainPage() {

    const [ state, setState ] = useState<MainPageState>({
        streaming: true
    });

    const [ game, setGame ] = useState<SchnapsenGame>({
        teams: [  ]
    });

    const stopStreaming = useCallback(() => {
        setState({...state, streaming: false });
    }, [ state ]);

    const start = useCallback(() => {
        setState({...state, streaming: true });
    }, [ state ]);

    const startNew = useCallback(() => {
        setState({...state, streaming: true });
        setGame({ teams: [  ] });
    }, [ state ]);

    const onDetect = useCallback((detections: Detection[]) => {

        if (detections.length > 0 && detections.length % 4 == 0) {
            let detectedCards = detections.map(detection => {
                return {
                    card: detection.class_name,
                    confidence: detection.confidence
                } as DetectedCard
            });

            const newGame = updateGame(game, detectedCards)
            setGame(newGame);

            if (Date.now() - lastVibrate > 2000) {
                lastVibrate = Date.now();

                audio.play();
                navigator.vibrate(200);
            }
        }

    }, [game]);

    const isValidDetectionCount = count => count > 0 && count % 4 == 0;
    return (
        <div>
            { state.streaming
                ? <VideoStream onDetect={onDetect} isValidDetectionCount={isValidDetectionCount} preset="schnapsen" />
                : <div>
                    <p><button onClick={start}>Start</button></p>
                    <p><button onClick={startNew}>Start New</button></p>
                  </div> }

            <table>
                {game.teams.map(team => <TeamComponent key={team.index} team={team} />)}
            </table>

            { state.streaming ? <button onClick={stopStreaming}>Stop</button> : null }
        </div>

    );
}

export default SchnapsenMainPage;
