// detect player cards

import {DetectedCard} from "../../poker/service/PlayerService";

export interface Team {
    index: number,
    cards: DetectedCard[],
    value: number
}

export interface SchnapsenGame {
    teams: Team[]
}

function hasTeamCards(team: Team, cards: DetectedCard[]): boolean {
    return team.cards.some(teamCard => cards.some(card => card.card === teamCard.card));
}

export function updateGame(game: SchnapsenGame, cards: DetectedCard[]): SchnapsenGame {

    // check if any of the given cards is already in the game
    let teamWithCards = game.teams.find(team => hasTeamCards(team, cards));

    let value = 0;
    cards.forEach(card => {
        if (card.card[0] === 'J') {
            value += 2;
        } else if (card.card[0] === 'Q') {
            value += 3;
        } else if (card.card[0] === 'K') {
            value += 4;
        } else if (card.card[0] === 'A') {
            value += 11;
        } else if (card.card[0] === 'T') {
            value += 10;
        }
    });

    let newTeams = [...game.teams];
    if (teamWithCards) {
        newTeams[newTeams.indexOf(teamWithCards)] = {...teamWithCards, cards: cards, value: value };
    } else {
        newTeams.push({cards: cards, index: newTeams.length, value: value});
    }

    return { ...game, teams: newTeams };

}